import { useContext, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useObtainTopicsData } from './useObtainTopicsData';
import { FilterDatesContext } from 'context/filterDatesContext';
import { IRO } from '../services/mockIROsService';
import checkFormErrors from 'utils/checkFormErrors';

export type DMIROFormData = {
  topic: SelectOptionFormat;
  subtopic: SelectOptionFormat;
  description: string;
  source: string;
  type: SelectOptionFormat;
  importanceScore: number | undefined;
  errors: ErrorType[];
};

const initialFormValues: DMIROFormData = {
  topic: { id: '', name: '' },
  subtopic: { id: '', name: '' },
  description: '',
  source: '',
  type: { id: '', name: '' },
  importanceScore: undefined,
  errors: []
};

export const iroTypes = [
  { id: 'positive_impact', name: 'Impacto positivo' },
  { id: 'negative_impact', name: 'Impacto negativo' },
  { id: 'opportunity', name: 'Oportunidad' },
  { id: 'risk', name: 'Riesgo' }
];

export type UseDMIROsFormProps = {
  iroData?: IRO;
  onSubmit?: (values: DMIROFormData) => Promise<void>;
};

export const adaptIRODataToFormValues = (iro: IRO): DMIROFormData => {
  return {
    ...iro,
    topic: { id: iro.topic, name: iro.topic },
    subtopic: { id: iro.subtopic, name: iro.subtopic },
    type: { id: iro.type, name: iro.type },
    errors: []
  };
};

export const adaptFormValuesToIROData = (values: DMIROFormData): IRO => {
  return {
    id: crypto.randomUUID(),
    topic: values.topic.name,
    subtopic: values.subtopic.name,
    type: values.type.id as IRO['type'],
    description: values.description,
    source: values.source,
    importanceScore: values.importanceScore
  };
};

export const useDMIROsForm = ({ iroData, onSubmit }: UseDMIROsFormProps = {}) => {
  const {
    i18n: { resolvedLanguage },
    t: tG
  } = useTranslation();
  const { t } = useTranslation('', {
    keyPrefix: 'doubleMateriality.iros'
  });
  const { startDate } = useContext(FilterDatesContext);
  const year = startDate.split('/')[2];

  const [formData, setFormData] = useState<DMIROFormData>(initialFormValues);

  useEffect(() => {
    (function initializeFormData() {
      if (iroData) setFormData(adaptIRODataToFormValues(iroData));
    })();
  }, [JSON.stringify(iroData)]);

  /* Fetch topics data */
  const { data } = useObtainTopicsData({
    year,
    language: resolvedLanguage
  });
  const { topics } = data ?? {};

  /* Computed values */
  const selectableTopics = useMemo(() => {
    const uniqueTopics = new Set(topics?.map((topic) => topic.topic));
    return Array.from(uniqueTopics).map((topic) => ({
      id: topic,
      name: topic
    }));
  }, [topics]);

  const selectableSubtopics = useMemo(
    () =>
      topics?.map((topic) => ({
        id: topic.id,
        name: topic.subtopic
      })) ?? [],
    [topics]
  );

  const iroTypes = useMemo(() => {
    return [
      { id: 'positive_impact', name: t('types.positive_impact') },
      { id: 'negative_impact', name: t('types.negative_impact') },
      { id: 'opportunity', name: t('types.opportunity') },
      { id: 'risk', name: t('types.risk') }
    ];
  }, [t]);

  /* Validations */
  const handleErrors = () => {
    const optionals = ['errors'];

    if (!formData.importanceScore) optionals.push('importanceScore');

    const errors = checkFormErrors(formData, [], optionals);

    if (!formData.importanceScore || formData.importanceScore < 0 || formData.importanceScore > 100)
      errors.push({
        error: 'importanceScore',
        description: t('form.importanceScore.mustBeBetween0And100')
      });

    setFormData((prev) => ({ ...prev, errors }));
    return errors.length ? errors : false;
  };

  const handleSubmit = async () => {
    if (handleErrors()) return;

    if (onSubmit) {
      await onSubmit(formData);
      resetForm();
    }
  };

  const resetForm = () => {
    setFormData(initialFormValues);
  };

  return {
    formData,
    setFormData,
    selectableTopics,
    selectableSubtopics,
    iroTypes,
    handleSubmit,
    resetForm,
    handleErrors
  };
};
