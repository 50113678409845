export type IRO = {
  id: string;
  topic: Topic['topic'];
  subtopic: Topic['subtopic'];
  description: string;
  source: string;
  type: 'positive_impact' | 'negative_impact' | 'opportunity' | 'risk';
  importanceScore: number | undefined;
};

const mockIROs: IRO[] = [
  {
    id: '1',
    topic: 'Cambio climático',
    subtopic: 'Emisiones de gases de efecto invernadero',
    description: 'Descripción del IRO 1',
    source: 'EINF',
    type: 'risk',
    importanceScore: 10
  },
  {
    id: '2',
    topic: 'Derechos humanos',
    subtopic: 'Trabajo infantil',
    description: 'Descripción del IRO 2',
    source: 'Análisis de Riesgos',
    type: 'negative_impact',
    importanceScore: 30
  },
  {
    id: '3',
    topic: 'Cambio climático',
    subtopic: 'Emisiones de gases de efecto invernadero',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    source: 'EINF',
    type: 'risk',
    importanceScore: 50
  }
];

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const mockIROsService = {
  getAllIROs: async (): Promise<IRO[]> => {
    await delay(500);
    return [...mockIROs];
  },

  getIROById: async (id: string): Promise<IRO | undefined> => {
    await delay(300);
    return mockIROs.find((iro) => iro.id === id);
  },

  createIRO: async (iroData: IRO): Promise<IRO> => {
    await delay(500);
    const newIRO = {
      ...iroData,
      id: (mockIROs.length + 1).toString(),
      type: iroData.type
    };
    mockIROs.push(newIRO);
    return newIRO;
  },

  updateIRO: async ({ id, data }: { id: string; data: IRO }): Promise<IRO> => {
    await delay(500);
    const index = mockIROs.findIndex((iro) => iro.id === id);
    if (index === -1) {
      throw new Error(`IRO with id ${id} not found`);
    }

    const updatedIRO = { ...data, id };
    mockIROs[index] = updatedIRO;
    return updatedIRO;
  },

  deleteIRO: async (id: string): Promise<{ success: boolean }> => {
    await delay(500);
    const index = mockIROs.findIndex((iro) => iro.id === id);
    if (index === -1) {
      throw new Error(`IRO with id ${id} not found`);
    }

    mockIROs.splice(index, 1);
    return { success: true };
  }
};
