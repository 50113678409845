import OnboardingImageWrapper from '../onboardingImageWrapper/OnboardingImageWrapper';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import { useTranslation } from 'react-i18next';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import Button from 'components/ui/button/Button';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import LoaderLogin from 'components/ui/loaders/loaderLogin/LoaderLogin';
import { TOTPCode } from '../totpCodeInput/TOTPCode';
import { useSignIn } from './hooks/useSignIn';
import { EmailConfirmation } from '../emailConfirmation/EmailConfirmation';
import { InputPassword } from 'components/ui/formComponents2/inputPassword/InputPassword';

export const SignIn = () => {
  const { t } = useTranslation('', { keyPrefix: 'signIn' });
  const navigate = useNavigate();

  const {
    currentStep,
    steps,
    handleSubmitStep,
    registerField,
    setFieldValue,
    email,
    errors,
    loading,
    blocked,
    clearErrors
  } = useSignIn();

  if (currentStep === steps.loading) return <LoaderLogin />;

  if (currentStep === steps.emailConfirmation) {
    return (
      <EmailConfirmation
        email={email}
        handleConfirmCode={handleSubmitStep}
        inputParams={registerField('emailCode')}
        loading={loading}
        error={errors.emailCode}
      />
    );
  }

  if (currentStep === steps.totp) {
    return (
      <OnboardingImageWrapper title={t('mfaTitle')} subtitle={t('mfaSubtitle')}>
        <FormWrapper className='w-80'>
          <TOTPCode
            onSubmit={(value) => {
              setFieldValue('totpCode', value);
              handleSubmitStep();
            }}
            errorMessage={errors.totpCode?.description}
            onChangeCallback={() => clearErrors('totpCode')}
            isLoading={loading}
          />
        </FormWrapper>
      </OnboardingImageWrapper>
    );
  }

  return (
    <OnboardingImageWrapper title={t('title')} subtitle=''>
      <FormWrapper className='w-80'>
        <form onSubmit={handleSubmitStep} className='flex-col items-center gap-2'>
          <div className='flex-col items-center gap-3 w-full'>
            <InputWrapper label={t('email')} iconV2='user' error={errors.email}>
              <InputText {...registerField('email')} placeholder={t('email')} />
            </InputWrapper>
            {currentStep === steps.password && (
              <div className='flex-col gap-3 w-full'>
                <InputWrapper label={t('password')} iconV2='key' error={errors.password}>
                  <InputPassword {...registerField('password')} placeholder={t('password')} />
                </InputWrapper>
                <div className='flex justify-end gap-1 w-full font-14'>
                  {t('forgotPassword')}
                  <Button
                    type='button'
                    className='font-14'
                    lookAndFeel='link'
                    onClick={() => navigate(`${ROUTES.FORGOT_PASSWORD}/${email}`)}>
                    {t('password').toLowerCase()}
                  </Button>
                </div>
              </div>
            )}
          </div>
          <FormButtonSection className='flex-col items-center gap-2 w-80 mt-4'>
            <Button
              type='submit'
              lookAndFeel='primary'
              text={t('startNow')}
              size='medium'
              loading={loading}
              disabled={blocked}
              onClick={handleSubmitStep}
            />
            <div className='font-14'>
              {t('dontHaveAccount')}{' '}
              <Button
                type='button'
                className='font-14'
                lookAndFeel='link'
                onClick={() => navigate(ROUTES.SIGNUP)}>
                {t('signUp')}
              </Button>
            </div>
          </FormButtonSection>
        </form>
      </FormWrapper>
    </OnboardingImageWrapper>
  );
};
