import apiFetch from 'utils/apiFetch';
import { jwtDecode } from 'jwt-decode';
import { localStorageGetItem, localStorageSetItem } from 'utils/localStorage';
import { generateQueryParamsFromObject, getUrl } from 'utils/url';

type JwtPayload = {
  sub: string;
  email_verified: boolean;
  iss: string;
  'cognito:username': string;
  origin_jti: string;
  aud: string;
  event_id: string;
  token_use: string;
  auth_time: number;
  id: string;
  exp: number;
  iat: number;
  jti: string;
  email: string;
};

export async function authParseLoginCallback() {
  if (process.env.REACT_APP_ENVIRONMENT === 'local') {
    return {
      user_id: '',
      email: '',
      id: ''
    };
  }
  try {
    return localStorageGetItem('user_data');
  } catch (err) {
    console.error('Error in auth parse login callback', err);
    throw err;
  }
}

export const authSignUp = async ({ email, password }: { email: string; password: string }) => {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('password', password);
  const { data } = await apiFetch<{
    sign_up_response: boolean;
    local_sign_up: boolean;
  }>('POST', '/v1/auth/sign_up', formData, {
    'Content-Type': 'multipart/form-data'
  });

  return {
    signUpResponse: data.sign_up_response,
    localSignUp: data.local_sign_up
  };
};

export const confirmSignUp = async ({ email, code }: { email: string; code: string }) => {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('confirmation_code', code);
  const { data } = await apiFetch<boolean>('POST', '/v1/auth/confirm_sign_up', formData, {
    'Content-Type': 'multipart/form-data'
  });

  return data;
};

type TOTPData = {
  totp_secret_code: string;
  totp_qr_code: string;
};

export const associateTotpMFA = async ({ email }: { email: string }) => {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('access_token', localStorageGetItem('access_token') || '');
  const { data } = await apiFetch<TOTPData>('POST', '/v1/auth/associate_software_token', formData, {
    'Content-Type': 'multipart/form-data'
  });

  return data;
};

export const enableTotpMFA = async ({ email, totpCode }: { email: string; totpCode: string }) => {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('access_token', localStorageGetItem('access_token') || '');
  formData.append('totp_code', totpCode);
  const { data } = await apiFetch<string>('POST', '/v1/auth/enable_totp_mfa', formData, {
    'Content-Type': 'multipart/form-data'
  });

  return data;
};

export const disableTotpMFA = async ({ email }: { email: string }) => {
  const formData = new FormData();
  formData.append('username', email);
  const { data } = await apiFetch<boolean>('POST', '/v1/auth/disable_totp_mfa', formData, {
    'Content-Type': 'multipart/form-data'
  });

  return data;
};

export const resendConfirmationCode = async ({ email }: { email: string }) => {
  const formData = new FormData();
  formData.append('username', email);
  const { data } = await apiFetch<boolean>('POST', '/v1/auth/resend_confirmation_code', formData, {
    'Content-Type': 'multipart/form-data'
  });

  return data;
};

export type MFAResponse = {
  challenge_name: string;
  session: string;
};

export type NonMFAData = {
  email: string;
  id: string;
  user_id: string;
  access_token: string;
  id_token: string;
  refresh_token: string;
};

type LoginResponse = NonMFAData | MFAResponse;

export const authLogin = async ({ email, password }: { email: string; password: string }) => {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('password', password);
  const { data } = await apiFetch<LoginResponse>('POST', '/v1/auth/login', formData, {
    'Content-Type': 'multipart/form-data'
  });

  if (data instanceof Object && 'challenge_name' in data) {
    return {
      mfaEnabled: true,
      ...data
    };
  }

  const tokenInfo = jwtDecode<JwtPayload>(data.id_token);

  const userData = {
    mfaEnabled: false,
    email: tokenInfo.email,
    id: tokenInfo.id,
    user_id: tokenInfo.id,
    access_token: data.access_token,
    id_token: data.id_token
  };

  localStorageSetItem('access_token', data.access_token);
  localStorageSetItem('id_token', data.id_token);
  localStorageSetItem('refresh_token', data.refresh_token);
  localStorageSetItem('user_data', JSON.stringify(userData));

  return userData;
};

export const confirmMFALogin = async ({
  email,
  session,
  mfa_authenticator_code
}: {
  email: string;
  session: string;
  mfa_authenticator_code: string;
}) => {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('session', session);
  formData.append('mfa_authenticator_code', mfa_authenticator_code);
  const { data } = await apiFetch<NonMFAData>('POST', '/v1/auth/confirm_mfa_login', formData, {
    'Content-Type': 'multipart/form-data'
  });

  const tokenInfo = jwtDecode<JwtPayload>(data.id_token);

  const userData = {
    email: tokenInfo.email,
    id: tokenInfo.id,
    user_id: tokenInfo.id,
    access_token: data.access_token,
    id_token: data.id_token
  };

  localStorageSetItem('access_token', data.access_token);
  localStorageSetItem('id_token', data.id_token);
  localStorageSetItem('refresh_token', data.refresh_token);
  localStorageSetItem('user_data', JSON.stringify(userData));

  return userData;
};

export const isMFAActive = async ({ email }: { email: string }) => {
  const { data } = await apiFetch<boolean>(
    'GET',
    getUrl('/v1/auth/is_mfa_activated', {
      queryParams: generateQueryParamsFromObject({
        username: encodeURIComponent(email)
      })
    })
  );
  return data;
};

export const forgotPassword = async ({ email }: { email: string }) => {
  const formData = new FormData();
  formData.append('username', email);
  const { data } = await apiFetch<boolean>('POST', '/v1/auth/forgot_password', formData, {
    'Content-Type': 'multipart/form-data'
  });

  return data;
};

export const confirmForgotChangePassword = async ({
  email,
  newPassword,
  emailCode
}: {
  email: string;
  newPassword: string;
  emailCode: string;
}) => {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('new_password', newPassword);
  formData.append('confirmation_code', emailCode);
  const { data } = await apiFetch<boolean>('POST', '/v1/auth/confirm_forgot_password', formData, {
    'Content-Type': 'multipart/form-data'
  });

  return data;
};

export const changePassword = async ({
  email,
  oldPassword,
  newPassword
}: {
  email: string;
  oldPassword: string;
  newPassword: string;
}) => {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('old_password', oldPassword);
  formData.append('new_password', newPassword);
  formData.append('access_token', localStorageGetItem('access_token') || '');
  const { data } = await apiFetch<boolean>('POST', '/v1/auth/change_password', formData, {
    'Content-Type': 'multipart/form-data'
  });

  return data;
};

export const authLogout = async () => {
  try {
    const formData = new FormData();
    formData.append('access_token', localStorageGetItem('access_token') || '');
    apiFetch('POST', '/v1/auth/logout', formData, {
      'Content-Type': 'multipart/form-data'
    });

    localStorage.setItem('x-user-id', '');
    localStorage.setItem('x-organization-id', '');
    localStorage.setItem('x-user-email', '');
    localStorage.setItem('user', '');
  } finally {
    window.location.reload();
  }
};

export const authGetIdToken = async (): Promise<string> => {
  let token = localStorageGetItem('id_token');
  const clerkSession = JSON.parse(localStorageGetItem('clerk_session'));

  if (clerkSession) {
    // Get token
    const clerkTokenCookie = document.cookie.match(new RegExp('(^| )' + '__session' + '=([^;]+)'));
    token = clerkTokenCookie ? clerkTokenCookie[2] : '';
    localStorageSetItem('access_token', token);
    localStorageSetItem('id_token', token);
  } else {
    const decodedToken = jwtDecode<JwtPayload>(token);

    if (decodedToken.exp && decodedToken.exp < Date.now() / 1000) {
      const { idToken } = await refreshToken();
      token = idToken;
    }
  }
  return token;
};

type RefreshTokenResponse = {
  token_type: string;
  expires_in: number;
  access_token: string;
  id_token: string;
};

export const refreshToken = async () => {
  const formData = new FormData();
  formData.append('refresh_token', localStorageGetItem('refresh_token') || '');
  const { data } = await apiFetch<RefreshTokenResponse>(
    'POST',
    '/v1/auth/refresh_token',
    formData,
    {
      'Content-Type': 'multipart/form-data'
    }
  );
  localStorageSetItem('access_token', data.access_token);
  localStorageSetItem('id_token', data.id_token);

  return { refreshToken: data.access_token, idToken: data.id_token };
};

export function authCheckSession(): boolean {
  return !!localStorageGetItem('id_token');
}
