import InputDateFilterSelect, {
  DateOptions
} from 'components/ui/formComponents2/inputDateFilterSelect/InputDateFilterSelect';
import { FilterDatesContext } from 'context/filterDatesContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { localStorageSetItem } from 'utils/localStorage';

const DateFilter = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });

  const { startDate, endDate, setStartDate, setEndDate, setDateError } =
    useContext(FilterDatesContext);

  const handleSetStartDate = (value: string) => {
    setStartDate(value);
    localStorageSetItem('start_date_dashboard', value);
  };

  const handleSetEndDate = (value: string) => {
    setEndDate(value);
    localStorageSetItem('end_date_dashboard', value);
  };

  return (
    <InputDateFilterSelect
      setStartDate={handleSetStartDate}
      setEndDate={handleSetEndDate}
      startDate={startDate}
      endDate={endDate}
      label={t('dateFilterLabel')}
      options={[
        DateOptions.THIS_YEAR,
        DateOptions.LAST_YEAR,
        DateOptions.LAST_30_DAYS,
        DateOptions.LAST_3_MONTHS,
        DateOptions.CUSTOM
      ]}
      keepDatesOpen={true}
    />
  );
};

export default DateFilter;
