import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import Button from '../../ui/button/Button';
import Icon from '../../ui/icon/Icon';
import Modal from '../../ui/modal/Modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import useFetchCustomEF from './hooks/useFetchCustomEF';
import { useState } from 'react';
import CardList from '../../ui/cardList/CardListRefactored';
import Card from '../../ui/cards/card/Card';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import ButtonDropdown from '../../ui/buttonDropdown/ButtonDropdown';
import AddGroupModal from './addGroupModal/AddGroupModal';
import DeleteModal from '../../ui/formComponents2/deleteModal/DeleteModal';
import EditGroupModal from './editGroupModal/EditGroupModal';
import { deleteCustomEFGroup } from '../../../services/api/customEF';
import { convertDateBackToFront } from '../../../utils/convertDates';
import { PermissionTags } from 'types/entitiesEnums/permissions';
import useRole from 'customHooks/useRole';

const CustomEF = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'customize' });
  const { t: tG } = useTranslation('translation', { keyPrefix: 'customize.group' });
  const { t: tN } = useTranslation();
  const navigate = useNavigate();

  const { fetchData, loading, data, addElement, editElement, removeElement } = useFetchCustomEF();
  const { permissions } = useRole();

  const [showCreate, setShowCreate] = useState(false);
  const [elementToEdit, setElementToEdit] = useState<ICustomEFGroupBack>();
  const [elementToDelete, setElementToDelete] = useState<ICustomEFGroupBack>();

  const onClickCard = (element: ICustomEFGroupBack) =>
    navigate(`${ROUTES.CUSTOM_EMISSION_FACTORS}/${element.id}?name=${element.name}`);

  const createDisabled = !permissions?.find(
    (permission: Permission) => permission.tag === PermissionTags.EMISSION_CUSTOM_EF_CREATE
  );

  return (
    <section className='vehicles'>
      <div className='vehicles__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{tN('measureMain.title')}</h3>
        <Breadcrumb />
      </div>
      <div id={'customize'} className='infinite-scroll-wrapper-card'>
        <InfiniteScroll
          dataLength={data.length}
          next={fetchData}
          hasMore={true}
          style={{ overflow: 'visible' }}
          endMessage={<></>}
          loader={undefined}
          scrollableTarget={'customize'}>
          <CardList
            listView={false}
            title={tG('new')}
            description={tG('newDescription')}
            buttons={
              <Button
                lookAndFeel={createDisabled ? 'blocked' : 'primary'}
                text={tG('create')}
                onClick={() => setShowCreate(true)}
                size='small'
                disabled={createDisabled}
              />
            }
            loading={loading}>
            <>
              {data.map((customEFGroup) => (
                <Card
                  key={customEFGroup.id}
                  listView={false}
                  onClick={() => onClickCard(customEFGroup)}>
                  <Card.Options>
                    <ButtonDropdown
                      button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
                      options={[
                        {
                          id: 'edit',
                          name: t('edit'),
                          onClick: () => setElementToEdit(customEFGroup)
                        },
                        {
                          id: 'delete',
                          name: t('delete'),
                          onClick: () => setElementToDelete(customEFGroup)
                        }
                      ]}
                    />
                  </Card.Options>
                  <Card.Content>
                    <span className='headline4-font'>{customEFGroup.name}</span>
                    <span className='subtitle3-font'>
                      {tN(`consumption.${customEFGroup.category}`)}
                    </span>
                    <Date
                      startDate={customEFGroup.group_start_date}
                      endDate={customEFGroup.group_end_date}
                    />
                  </Card.Content>
                </Card>
              ))}
            </>
          </CardList>
        </InfiniteScroll>
      </div>
      <Modal.WithPortal
        show={showCreate}
        onClose={() => setShowCreate(false)}
        width={'428px'}
        maxWidth={'428px'}>
        <AddGroupModal onClose={() => setShowCreate(false)} onAddGroup={addElement} />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={!!elementToEdit}
        onClose={() => setElementToEdit(undefined)}
        width={'428px'}
        maxWidth={'428px'}>
        <EditGroupModal
          onClose={() => setElementToEdit(undefined)}
          onEditGroup={editElement}
          id={elementToEdit?.id || ''}
        />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={!!elementToDelete}
        onClose={() => setElementToDelete(undefined)}
        width={'428px'}
        maxWidth={'428px'}>
        <DeleteModal
          id={elementToDelete?.id || ''}
          handleDelete={removeElement}
          onClose={() => setElementToDelete(undefined)}
          deleteElement={deleteCustomEFGroup}
        />
      </Modal.WithPortal>
    </section>
  );
};

type DateProps = {
  startDate?: string;
  endDate?: string;
};

const Date = ({ startDate, endDate }: DateProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'customize' });
  if (!startDate || !endDate)
    return <span className='subtitle3-font error-text-color'>{t('empty')}</span>;
  return (
    <span className='subtitle3-font'>{`${convertDateBackToFront(
      startDate
    )} - ${convertDateBackToFront(endDate)}`}</span>
  );
};

export default CustomEF;
