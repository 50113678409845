import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import DateFilter from './filters/DateFilter';
import CategoryFilter from './filters/CategoryFilter';
import MethodFilter from './filters/MethodFilter';
import SustainabilityRoadmap from '../../controlPanel/ControlPanel';
import { DashboardEsg } from '../../dashboardEsg/DashboardEsg';
import { useParams } from 'react-router-dom';
import { ESGModeContext } from 'context/esgModeContext';
import useCategoryOptions from './hooks/useCategoryOptions';

type Props = {
  children: React.ReactNode;
};

const LayoutV2 = ({ children }: Props) => {
  const { t } = useTranslation();

  const { mode, setMode } = useContext(ESGModeContext);
  const { frameworkName } = useParams();
  const categoryOptions: SelectOptionFormat[] = useCategoryOptions();

  const [category, setCategory] = useState<SelectOptionFormat<string>>(categoryOptions[0]);

  useEffect(() => {
    setCategory(categoryOptions.find((option) => option.id === mode) ?? categoryOptions[0]);
  }, [mode]);

  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const mode = queryParams.get('mode');

    if (mode) {
      setMode(mode);
    }
  }, [window.location.search]);

  const renderChildren = () => {
    if (mode === 'controlPanel' && !frameworkName) {
      return <SustainabilityRoadmap />;
    } else if (mode === 'socialAndGovernance') {
      return <DashboardEsg />;
    }
    return children;
  };

  return (
    <div className='dashboard-wrapper'>
      <section className='dashboard'>
        <div className='dashboard__header page-header'>
          <h3 className='semibold-font font-20 on-light-text-color'>{t('dashboard.title')}</h3>
          <h4 className='regular-font font-16 on-light-text-color'>{t('dashboard.subtitle')}</h4>
        </div>
        <div className='dashboard-wrapper__filters'>
          <DateFilter />
          <div className='vertical-line' />
          <CategoryFilter options={categoryOptions} category={category} setCategory={setCategory} />
          <MethodFilter show={category.id === 'environmental'} />
        </div>
        {renderChildren()}
      </section>
    </div>
  );
};

export default LayoutV2;
