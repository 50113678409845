import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import Button from '../../ui/button/Button';
import OnboardingImageWrapper from '../onboardingImageWrapper/OnboardingImageWrapper';
import './styles.scss';

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.removeItem('dashboard');
    localStorage.removeItem('user');
    localStorage.removeItem('hideSurveyModal');

    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      navigate(ROUTES.SIGNUP, { replace: true });
    }
  }, []);

  return (
    <OnboardingImageWrapper
      title={t('login.title')}
      subtitle={t('login.subtitle')}
      img={'/images/previewApp.png'}>
      <div className='login-form'>
        <div className='buttons'>
          <Button
            lookAndFeel='secondary'
            text={t('login.signUpNewAccount')}
            size='medium'
            onClick={() => navigate(ROUTES.SIGNUP)}
          />
          <Button
            lookAndFeel='primary'
            text={t('login.signInEmail')}
            size='medium'
            onClick={() => navigate(ROUTES.SIGNIN)}
          />
          <div className='flex row w-full'>
            <hr className='w-full mx-4 border-0 border-b-1 border-solid input-border-color' />
            <span className='text-gray-light'>{t('login.or')}</span>
            <hr className='w-full mx-4 border-0 border-b-1 border-solid input-border-color' />
          </div>
          <Button
            className='font-14'
            lookAndFeel='link-dark'
            text={t('login.signInSSO')}
            onClick={() => navigate(ROUTES.SIGNIN)}
          />
        </div>
        <div className='login-form__footer'>
          <span className='body1-font on-light-text-color'>{t('login.accessInDesktop')}</span>
          <img src='/images/icons/mobileRotate.svg' alt='mobile rotate' />
        </div>
      </div>
    </OnboardingImageWrapper>
  );
};

export default Login;
