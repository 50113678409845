import { zodResolver } from '@hookform/resolvers/zod';
import { setNotification } from 'actions/notification';
import { PasswordChecks } from 'components/onboarding/passwordChecks/PasswordChecks';
import Button from 'components/ui/button/Button';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import { InputPassword } from 'components/ui/formComponents2/inputPassword/InputPassword';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Modal from 'components/ui/modal/Modal';
import { adaptHookFormError } from 'lib/ReactHookForm/adaptError';
import { useTranslation } from 'react-i18next';
import { useChangePassword } from './hooks/useChangePassword';

type ChangePasswordModalProps = {
  onClose: () => void;
};

export const ChangePasswordModal = ({ onClose }: ChangePasswordModalProps) => {
  const { t } = useTranslation('', { keyPrefix: 'profile.securityAndAccesses.changePassword' });
  const { t: tGeneral } = useTranslation('', { keyPrefix: 'general' });

  const { errors, handleSubmit, isSubmitted, password, register } = useChangePassword({
    onSuccess: onClose
  });

  return (
    <>
      <Modal.Header title={t('title')} />
      <Modal.Content>
        <FormWrapper className='flex-col'>
          <InputWrapper
            label={t('oldPassword')}
            iconV2='key'
            error={adaptHookFormError(errors.oldPassword)}>
            <InputPassword {...register('oldPassword')} placeholder={t('oldPassword')} />
          </InputWrapper>
          <InputWrapper
            label={t('newPassword')}
            iconV2='key'
            error={adaptHookFormError(errors.newPassword)}>
            <InputPassword {...register('newPassword')} placeholder={t('newPassword')} />
          </InputWrapper>
          <InputWrapper
            label={t('confirmPassword')}
            iconV2='key'
            error={adaptHookFormError(errors.confirmNewPassword)}>
            <InputPassword {...register('confirmNewPassword')} placeholder={t('confirmPassword')} />
          </InputWrapper>
          <PasswordChecks isSubmitted={isSubmitted} password={password} />
        </FormWrapper>
        <FormButtonSection className='flex items-center gap-2' style={{ marginTop: '1em' }}>
          <Button
            lookAndFeel='secondary'
            text={tGeneral('cancel')}
            size='medium'
            onClick={onClose}
          />
          <Button
            lookAndFeel='primary'
            text={tGeneral('saveAndClose')}
            size='medium'
            onClick={handleSubmit}
          />
        </FormButtonSection>
      </Modal.Content>
    </>
  );
};
