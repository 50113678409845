import { zodResolver } from '@hookform/resolvers/zod';
import { setNotification } from 'actions/notification';
import { useErrorTranslations } from 'customHooks/translations';
import { useUser } from 'customHooks/useUser';
import { useFormWithHelpers } from 'lib/ReactHookForm/useFormWithHelpers';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changePassword } from 'services/authService';
import { buildPasswordValidator } from 'utils/auth';
import { z } from 'zod';

type UseChangePasswordProps = {
  onSuccess: () => void;
};

export const useChangePassword = ({ onSuccess }: UseChangePasswordProps) => {
  const { t } = useTranslation('', { keyPrefix: 'profile.securityAndAccesses.changePassword' });
  const { t: tError } = useErrorTranslations();
  const currentUser = useUser();

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    control,
    setError
  } = useFormWithHelpers({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    resolver: zodResolver(
      z
        .object({
          oldPassword: z.string().min(1, { message: tError('required') }),
          newPassword: buildPasswordValidator({
            errorTranslation: tError,
            email: currentUser?.email,
            fieldName: 'newPassword'
          }),
          confirmNewPassword: z.string()
        })
        .superRefine(({ newPassword, confirmNewPassword }, ctx) => {
          if (newPassword !== confirmNewPassword) {
            ctx.addIssue({
              code: 'custom',
              message: tError('passwordsDontMatch'),
              path: ['confirmNewPassword']
            });
          }
        })
    )
  });

  const exceptionsMapper = useMemo(
    (): Record<
      string,
      {
        field: keyof typeof errors;
        message: string;
      }
    > => ({
      PASSWORD_HISTORY_POLICY_VIOLATION: {
        field: 'newPassword',
        message: tError('passwordHistory')
      },
      INVALID_CREDENTIALS: {
        field: 'oldPassword',
        message: tError('invalidCredentials')
      }
    }),
    [tError]
  );

  const password = useWatch({ control, name: 'newPassword' });

  const submitChangePassword = async ({
    oldPassword,
    newPassword
  }: {
    oldPassword: string;
    newPassword: string;
  }) => {
    if (!currentUser?.email) return;
    try {
      await changePassword({ email: currentUser.email, oldPassword, newPassword });
      onSuccess();
      dispatch(setNotification(t('success')));
    } catch (error: any) {
      const errorCode = error?.response?.data?.code;
      const errorMessage = exceptionsMapper[errorCode];
      errorMessage && setError(errorMessage.field, { message: errorMessage.message });
    }
  };

  return {
    register,
    handleSubmit: handleSubmit(submitChangePassword),
    errors,
    isSubmitted,
    password
  };
};
