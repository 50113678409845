import { useEffect, useRef, useState } from 'react';

type TOTPCodeProps = {
  onSubmit: (value: string) => void;
  errorMessage?: string;
  isLoading?: boolean;
  onChangeCallback?: (value: string) => void;
};

const TOTP_LENGTH = 6;

export const TOTPCode = ({
  onSubmit,
  errorMessage,
  isLoading,
  onChangeCallback
}: TOTPCodeProps) => {
  const inputRef: any = useRef([]);
  const [totpValue, setTotpValue] = useState(new Array(TOTP_LENGTH).fill(''));

  useEffect(() => {
    if (inputRef.current[0]) inputRef.current[0].focus();
  }, []);

  const handleChange = (inputValue: string, index: number) => {
    if (isNaN(Number(inputValue))) return;

    const newValue = [...totpValue];
    newValue[index] = inputValue.slice(-1);
    setTotpValue(newValue);

    if (inputValue && index < TOTP_LENGTH - 1) inputRef.current[index + 1].focus();

    const finalValue = newValue.join('');
    finalValue.length === TOTP_LENGTH && onSubmit(finalValue);

    onChangeCallback && onChangeCallback(finalValue);
  };

  const handleClick = (index: number) => inputRef.current[index].setSelectionRange(1, 1);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && index > 0 && !totpValue[index]) {
      inputRef.current[index - 1].focus();
    }
    onChangeCallback && onChangeCallback(totpValue.join(''));
  };

  return (
    <div>
      <form className='flex gap-x-3 place-center justify-center py-10'>
        {totpValue.map((item, index) => (
          <input
            className={`border-1 border-solid ${
              errorMessage ? 'error-border-color' : 'border-neutral-gray-warm-40'
            } ${isLoading && 'input-disabled-bg-color'} rounded-4 w-10 h-10 text-center`}
            key={index}
            ref={(input) => (inputRef.current[index] = input)}
            value={item}
            placeholder=''
            onChange={(e) => handleChange(e.target.value, index)}
            onClick={() => handleClick(index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            disabled={isLoading}
          />
        ))}
      </form>
      <div className='flex place-center justify-center w-full'>
        {errorMessage && !isLoading && <span className='error-text-color'>{errorMessage}</span>}
      </div>
    </div>
  );
};
