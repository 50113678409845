import Button from 'components/ui/button/Button';
import Modal from 'components/ui/modal/Modal';
import { useDeleteIROMutation } from '../../queries/useIROsQuery';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  show: boolean;
  onClose: () => void;
  iroId: string;
};

export const DMIROsDeleteModal: React.FC<Props> = ({ show, onClose, iroId }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'doubleMateriality.iros.deleteModal'
  });
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteMutation = useDeleteIROMutation();

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteMutation.mutateAsync(iroId);
      onClose();
    } catch (error) {
      console.error('Error deleting IRO:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal.WithPortal show={show} onClose={onClose}>
      <Modal.Header title={t('title')} description={t('description')} />
      <Modal.Buttons>
        <Button lookAndFeel='secondary' text={t('delete')} size='medium' onClick={onClose} />
        <Button
          lookAndFeel='warning'
          text='Eliminar IRO'
          size='medium'
          onClick={handleDelete}
          disabled={isDeleting}
          loading={isDeleting}
        />
      </Modal.Buttons>
    </Modal.WithPortal>
  );
};
